import React, { lazy, Suspense,useCallback,useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./App.css";

import TrackingDetailsPage from "./components/TrackingDetails";
import BlogList from "./pages/BlogList";

import ShippingRateCalculator from "./pages/ShippingRateCalculator";
import ErrorPage from "./pages/ErrorPage";
import PincodeDetail from "./components/PincodeDetail";
import DashBoard from "./pages/DashBoard";

import Track from "./pages/Track";
import Coupons from "./pages/Coupons";
import CalculatorResult from "./components/CalculatorResult";
import Particular from "./pages/Particular";
import Footer from "./components/Footer";
import Application from "./pages/Application";
const HomePage = lazy(() => import("./pages/HomePage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const ServicesList = lazy(() => import("./pages/ServicesList"));
const Faq = lazy(() => import("./pages/Faq"));
const Media = lazy(() => import("./pages/Media"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const RefundandCancellation = lazy(() =>
  import("./pages/RefundandCancellation")
);
const TermandCondition = lazy(() => import("./pages/TermandCondition"));
const Career = lazy(() => import("./pages/Career"));
const NdrMangement = lazy(() => import("./pages/NdrMangement"));
const WeightReconciliation = lazy(() => import("./pages/WeightReconciliation"));
const CodRemittance = lazy(() => import("./pages/CodRemittance"));
const PickupLocation = lazy(() => import("./pages/PickupLocation"));
const MultipalPartner = lazy(() => import("./pages/MultipalPartner"));
const ApiIntegration = lazy(() => import("./pages/ApiIntegration"));
const Blogdetails = lazy(() => import("./pages/Blogdetails"));

const App = () => {
  const updateMetaTags = useCallback((title, description, keywords) => {
    document.title = title;
    document.querySelector('meta[name="description"]').setAttribute("content", description);
    document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
  },[])
  useEffect(()  => {
    updateMetaTags("Logistics, Shipping and Courier Services in India | Truxcargo",
    "Enhance your business operations in India with TruxCargo's comprehensive logistics, shipping, and courier services, delivering excellence at every step.",
    "Logistics, Shipping and Courier Services in India, Best domestic courier services in india, Domestic courier services in india Top courier services in india");
  }, [updateMetaTags]);



  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense
        fallback={
          <div className="loading-main">
            <img src="/loading.gif" alt="" srcset=""  className="w-50"/>
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage updateMetaTags={updateMetaTags} />}  />

          <Route path="/contactus" element={<ContactPage updateMetaTags={updateMetaTags}  />} />
          <Route path="/Service" element={<ServicesList updateMetaTags={updateMetaTags}  />} />
          <Route path="/faq" element={<Faq updateMetaTags={updateMetaTags} />} />
          <Route path="/media" element={<Media updateMetaTags={updateMetaTags} />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route
            path="/refundandcancellation"
            element={<RefundandCancellation />}
          />
          <Route path="/termandcondition" element={<TermandCondition />} />
          <Route path="/Career" element={<Career  updateMetaTags={updateMetaTags}  />} />
          <Route path="/ndr_managment" element={<NdrMangement updateMetaTags={updateMetaTags} />} />
          <Route
            path="/weightReconciliation"
            element={<WeightReconciliation updateMetaTags={updateMetaTags} />}
          />
          <Route path="/cod_remittance" element={<CodRemittance updateMetaTags={updateMetaTags} />} />
          <Route path="/multiple_pickup" element={<PickupLocation updateMetaTags={updateMetaTags}/>} />
          <Route
            path="/Multiple_ShippingPartner"
            element={<MultipalPartner updateMetaTags={updateMetaTags} />}
          />
          <Route
            path="/ShippingRateCalculator"
            element={<ShippingRateCalculator updateMetaTags={updateMetaTags} />}
          />
          <Route path="/shipping_rate_details" element={<CalculatorResult />} />

          <Route path="/ApiIntegration" element={<ApiIntegration updateMetaTags={updateMetaTags} />} />
          <Route path="/Dashboard-features" element={<DashBoard updateMetaTags={updateMetaTags} />} />
          <Route path="/blog" element={<BlogList updateMetaTags={updateMetaTags} />} />
          <Route path="/blogPost/:id/:title" element={<Blogdetails />} />
          <Route
            path="/multiple_tracking_details/:trackingNo" 
            element={<TrackingDetailsPage />}
          />
          <Route
            path="/tracking_details/:trackingNo"
            element={<Particular />}
          />
          <Route
            path="/pincode_details/:pincode_No"
            element={<PincodeDetail />}
          />
          <Route path="/track" element={<Track />} />
          <Route path="/shipment-coupons" element={<Coupons updateMetaTags={updateMetaTags} />} />
          <Route path="/truxcargo-application" element={<Application updateMetaTags={updateMetaTags} />} />

          <Route path="*" element={<ErrorPage  />} />
         
        </Routes>
        <Footer />

      </Suspense>
    </BrowserRouter>
  );
};

export default App;
